import { restClient } from "@/store/actions";
import { IRestResponse } from "@elite/restclient";

/**
 * Creates or updates a model by making either a POST or PUT request to the given url
 * @param model the model
 * @param url the endpoint url
 * @returns the response
 */
export async function createOrUpdateModel<TResponse>(
    model: { id?: number | undefined },
    url: string
): Promise<IRestResponse<TResponse>> {
    const isNew = model.id === 0;

    const response = isNew
        ? await restClient.postJson<TResponse>(url, model)
        : await restClient.putJson<TResponse>(url, model);

    return response;
}
