/**
 * Formats the given timestamp to en-us locale
 * @param {string} date
 * @returns {string}
 */
export const formatDate = (date: string): string => {
    return new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });
};
