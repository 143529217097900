import swal from "sweetalert";

/**
 * A wrapper to display a warning themed sweet alert for delete prompts
 * @param {string} prompt - The text that will be displayed on the sweet alert
 * @returns {Promise<boolean>}
 */
export const promptDelete = async (prompt: string): Promise<boolean> => {
    return swal({
        text: prompt,
        icon: "warning",
        dangerMode: true,
        buttons: {
            confirm: {
                text: "Yes"
            },
            cancel: true
        }
    }).then((confirm) => confirm);
};
