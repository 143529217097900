import { UploadFileInfo, UploadFileRestrictions } from "@progress/kendo-vue-upload";

/**
 * Creates form data containing the image data to be POSTed
 * @param {UploadFileInfo} image
 * @returns {FormData}
 */
export const createImageFormData = (image: UploadFileInfo): FormData => {
    const formData = new FormData();

    if (image.getRawFile !== undefined) {
        const rawImage = image.getRawFile();
        formData.append("form", rawImage);
    }

    return formData;
};

// Default maxAllowedContentLength
// See: https://docs.microsoft.com/en-us/iis/configuration/system.webserver/security/requestfiltering/requestlimits/#attributes
const MAX_FILE_SIZE_IN_BYTES = 30000000;

export const JPG_TOOLTIP_MESSAGE = "File should be a .jpg";
export const PNG_TOOLTIP_MESSAGE = "File should be a .png";
export const IMAGE_TOOLTIP_MESSAGE = "File should be a .jpg or .png";
export const PDF_TOOLTIP_MESSAGE = "File should be a .pdf";

export const COURSE_LOGO_IMAGE_TOOLTIP_MESSAGE = `${PNG_TOOLTIP_MESSAGE}, recommended width is 200px`;
export const COURSE_HEADER_IMAGE_TOOLTIP_MESSAGE = `${JPG_TOOLTIP_MESSAGE}, recommended width is 500px`;
export const PROGRESS_LEVEL_IMAGE_TOOLTIP_MESSAGE = `${PNG_TOOLTIP_MESSAGE}, recommended width is 250px`;
export const COMMUNITY_GALLERY_IMAGE_TOOLTIP_MESSAGE = `${PNG_TOOLTIP_MESSAGE}, recommended width is 250px`;

export const jpgUploadFileRestrictions: UploadFileRestrictions = {
    allowedExtensions: [".jpg"],
    maxFileSize: MAX_FILE_SIZE_IN_BYTES
};

export const pngUploadFileRestrictions: UploadFileRestrictions = {
    allowedExtensions: [".png"],
    maxFileSize: MAX_FILE_SIZE_IN_BYTES
};

export const imageUploadFileRestrictions: UploadFileRestrictions = {
    allowedExtensions: [".jpg", ".png"],
    maxFileSize: MAX_FILE_SIZE_IN_BYTES
};

export const pdfUploadFileRestrictions: UploadFileRestrictions = {
    allowedExtensions: [".pdf"],
    maxFileSize: MAX_FILE_SIZE_IN_BYTES
};

/**
 * Adds timestamp to azure blob URL to bust cache
 * @param {string} imageUrl
 * @returns {string}
 */
export const createUniqueUrl = (imageUrl: string): string =>
    `${decodeURIComponent(imageUrl)}?${new Date().getTime()}`;
