/**
 * Converts stored decimal number percent value to whole number so it can be used by humans
 * @param {number} decimal
 * @returns {number} the converted value
 */
const convertDecimalToWhole = (decimalNumber: number): number =>
    Math.floor(decimalNumber * 100);

/**
 * Converts whole number percent value to decimal so it can be stored
 * @param {number} wholeNumber
 * @returns {number} the converted value
 */
const convertWholeToDecimal = (wholeNumber: number): number =>
    wholeNumber / 100;

export { convertDecimalToWhole, convertWholeToDecimal };
